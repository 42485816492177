import React from 'react';

export default function NotFound() {
  return (
    <div className="w-screen h-screen flex">
      <div className="m-auto">
        <h1 className="text-8xl">404</h1>
        <p className="my-2">Not found</p>
      </div>
    </div>
  );
}
